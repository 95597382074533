(function ($) {
    function fixFooterPadder() {
        var bodyWerapper = $('.outer-wrapper');
        var footer = $('footer.footer');
        bodyWerapper.css('padding-bottom', footer.outerHeight() + 'px');

        if ($('#wpadminbar').length) {
            $('html').attr('style', 'margin: 0 !important;');
            $('body').css('padding-top', '32px');
        }
    }

    $(document).ready(fixFooterPadder);
    $(window).ready(fixFooterPadder);
    $(window).resize(fixFooterPadder);

    $(document).ready(function(){
        var topLink = $('#back-top');
        if(topLink.length) {
            topLink.click(function(e){
                e.preventDefault();
                $("html, body").animate({ scrollTop: 0 }, "slow");
            });
        }
    });
})(jQuery);

(function ($) {
    function StickyCTA(cta, ph) {
        this.cta = cta;
        this.ph = ph;
        this.no_stick = false;

        this.adjustPlaceholderHeight = function () {
            ph.css('height', cta.height() + 'px');
        }.bind(this);

        this.setSticky = function () {
            this.cta.addClass('sticky');
            this.ph.addClass('placed');
        }.bind(this);

        this.setStatic = function () {
            this.cta.removeClass('sticky');
            this.ph.removeClass('placed');
        }.bind(this);

        this.disable = function (e) {
            e.preventDefault();
            this.no_stick = true;
            this.close.remove();
            this.setStatic();
        }.bind(this);

        this.scroll = function () {
            this.adjustPlaceholderHeight();
            var scrollTop = $(window).scrollTop();
            var scrollBottom = scrollTop + $(window).height();

            if (scrollBottom >= (this.ph.offset().top + this.ph.height()) || this.no_stick) {
                this.setStatic(this);
            } else {
                this.setSticky(this);
            }
        }.bind(this);

        this.init = function () {
            $(window).on('load', function () {
                this.scroll();
            }.bind(this));
            $(window).resize(function () {
                this.scroll(this)
            }.bind(this));
            $(window).scroll(function () {
                this.scroll(this);
            }.bind(this));
            this.scroll(this);
        }.bind(this);
    }

    $(document).ready(function () {
        var footer = $('footer.footer');
        var placeholder = footer.find('.buttons-ph');
        var cta_container = footer.find('.buttons');

        if (cta_container.length && placeholder.length) {
            var cta = new StickyCTA(cta_container, placeholder);
            cta.init();
        }
    });
})(jQuery);